.sidebar-item {
  height: 60px;
  cursor: pointer;
  &.active {
    border-left: 3px solid var(--wb-zc3);
  }

  .icon {
    color: var(--wb-zc3) !important;
    margin-left: 16px;
    margin-top: 18px;
  }

  &:hover {
    background-color: var(--wb-background-gray-light);
  }
}
