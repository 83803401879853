@import "../variables.scss";

.navbar {
  z-index: 10;
  height: $--app-height;
  padding: 1rem 1rem;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16) !important;
}

.menu-wrapper {
  display: flex;
  flex-direction: row;
}

// FIXME: !take a proper look at css hierarchy.
.bg-light {
  background-color: var(--wb-white) !important;
}

.logo {
  margin-top: -3px;
  margin-right: 3rem;
  margin-left: 40px;
  height: 30px;
}

.navbar-brand {
  font-size: 1rem !important;
  font-weight: 300;
  margin-left: 18px;
  color: var(--wb-zc3) !important;
}

a.nav-link {
  font-weight: 400;
  margin-left: 20px;
  color: var(--wb-black) !important;
  &:hover {
    color: var(--wb-blue) !important;
  }
}

.navbar-light .navbar-nav .nav-link.active {
  color: var(--wb-blue) !important;
}

.header-icon {
  margin-right: 0.75rem;
  margin-top: -2px;
}

.navbar-nav {
  margin-right: 2rem !important;
}
