.quantity-wrapper {
  background-color: var(--wb-background-gray-light);
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
  overflow: hidden;

  .card-wrapper {
    background-color: white;
    width: 100%;
    min-height: 240px;
    padding: 0.5rem;
    // FIX: first
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    h5 {
      font-weight: 400;
      font-size: 1rem;
    }

    .visual-wrapper {
      height: 100%;
      // background-color: var(--wb-blue);
    }
  }
}

.capitals {
  text-transform: uppercase;
}
