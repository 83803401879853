.streetview-container-split {
  position: relative;
  z-index: 12;
  width: 100%;
  height: 100%;
  background-color: white;
}

.streetview-container-modal {
  position: absolute;
  z-index: 12;
  width: 100%;
  height: 100%;
  background-color: white;
}
