.selector-wrapper {
  background-color: var(--wb-zc5);
  display: flex;
  height: 40px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */

  &:hover {
    background-color: var(--wb-zc4) !important;
  }

  .selector-text {
    margin-top: 6px;
    padding-left: 1rem;
    padding-right: 2rem;
  }
}

.dropdown-list {
  min-width: 140px !important;
  background-color: white !important;
  border-radius: 0 !important;
  border-style: none !important;
}

.dropdown-item {
  &:hover {
    background-color: var(--wb-background-gray-light) !important;
  }
  &.active {
    background-color: var(--wb-zc4) !important;
  }
}
