.unity {
  position: relative;
  height: 100% !important;
  width: 100%;
}

.unity-model-wrapper {
  height: 100%;
}

.user-interface-wrapper {
  pointer-events: none;
}

.unity-data-overlay {
  position: fixed;
  display: flex;
  z-index: 2;
  bottom: 60px;
  right: 150px;
  transform: translate(+50%);
  color: white;
  pointer-events: all;
}

.current-profile {
  background-color: var(--wb-zc4);
  min-width: 40px;
  min-height: 40px;
  margin-right: 0.25rem;
  font-weight: 500;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard */

  .round-border {
    margin-top: 5px;
    margin-left: 5px;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    height: 30px;
    width: 30px;
    border-radius: 18px;
  }

  & p {
    margin-left: 9px;
  }
}
