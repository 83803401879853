.sidebar {
  position: fixed;
  width: 60px;
  left: 0;
  height: calc(100vh - 60px);
  border-right: 1px solid var(--wb-background-gray-light);

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 60px;
  }
}
