.modal-container {
  z-index: 9;
  position: absolute;
  min-width: calc(100vw - 60px);
  min-height: calc(100vh - 60px);
  margin-left: 60px;
  //background-color: var(--wb-zc3);
  //overflow: hidden;
}

.modal-close-button {
  position: fixed;
  right: 15px;
  top: 75px;
  cursor: pointer;
  color: white;
}
