// @import "bootstrap/dist/css/bootstrap.min.css";
@import "../variables.scss";
/* Imports Design System Tokens */
@import url("https://projectdigitalehuisstijl.zeroheight.com/api/design_tokens?auth=erh0lJcf2UMOLJTMMIVTpw&format=css&id=40498&colorPrefix=wb-&fontPrefix=wb-&dl=1");
// @import "~react-vis/dist/style";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid {
  padding: 0 !important;
}

.floating-quantity-container {
  position: fixed;
  right: 1rem;
  top: 5rem;
  z-index: 10;
}
